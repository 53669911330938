export default {
    home: 'home',
    about: 'about',
    secure: 'Secure portal',
    portal: 'Portal',
    family: {
        header: {
            title: 'DAVID VEILLEUX',
            subtitle: 'FAMILY OFFICE',
        },
        hat: 'FAMILY OFFICE',
        title: 'ENSURE YOUR FAMILY\'S PROSPERITY',
        text: 'Tailored to your unique wealth, our insurance strategies enhance your assets and support their growth for generations.',
        cta: 'CREATE YOUR OWN PATH',
        relax: 'RELAX WHILE WE PREPARE YOUR EXPERIENCE',
        nav: 'FAMILY OFFICE',
        footer: 'DISCOVER DAVID VEILLEUX FAMILY OFFICE',
    }
}
