<template>
	<div :class="['intersite-nav', { 'intersite-nav--expanded': expanded }]">
		<div class="intersite-nav__wrapper">
			<div @click.stop="toggleExpanded()" class="intersite-nav__tab">
				{{ $beet.options.intersitesNav.tabLabel }}
				<img src="@/assets/icons/arrowNav.svg" class="intersite-nav__arrow" alt="Arrow" />
			</div>
			<div @click.stop.self="toggleExpanded()" class="intersite-nav__content">
				<Card :img="$beet.options.intersitesNav.majoris.image" type="dark">
					<h3 class="card__title">{{ $beet.options.intersitesNav.majoris.title }}</h3>
					<p class="card__text">{{ $beet.options.intersitesNav.majoris.content }}</p>
					<a href="https://majorisassurances.ca/" class="button">{{
						$beet.options.intersitesNav.visitWebsite
					}}</a>
				</Card>
				<Card :img="$beet.options.intersitesNav.dvfo.image" type="dark">
					<h3 class="card__title">{{ $beet.options.intersitesNav.dvfo.title }}</h3>
					<p class="card__text">{{ $beet.options.intersitesNav.dvfo.content }}</p>
					<a href="https://dvfo.dvsf.ca/" class="button">{{ $beet.options.intersitesNav.visitWebsite }}</a>
				</Card>
			</div>
		</div>
		<div @click.stop="toggleExpanded()" class="intersite-nav__bg-darken"></div>
	</div>
</template>
<script>
import Card from "./Card.vue";

export default {
	name: "IntersiteNav",
	components: { Card },
	data() {
		return {
			expanded: false,
		};
	},
	methods: {
		toggleExpanded() {
			this.expanded = !this.expanded;
			// Scroll to top of the div when not expanded to fix graphical glitch
			if (!this.expanded) {
				this.$el.scrollTo(0, 0);
			}
		},
	},
};
</script>
