<template>
  <div>
    <div class="footer">
      <div class="footer__flex">
        <div class="flex__reverse">
          <img class="footer__logo" src="@/assets/logo_full.svg" alt="David Veilleux Cabinet de services financiers">
          <div class="footer__socials">
            <a v-for="(social, name) in $beet.options.general.socials" :key="'social-' + name" :href="social"
               target="_blank" class="footer__social">
              <icon :id="name"/>
            </a>
          </div>
        </div>
        <div v-for="(address, index) in $beet.options.general.offices" :key="'place-' + index"
             :class="{'mt-50': isMobile}">
          <p class="footer__address">{{ address.name }}</p>
          <div class="footer__icon-line">
            <Icon id="address" class="footer__icon"/>
            <div>{{ address.address }}</div>
          </div>
          <div class="footer__icon-line">
            <Icon id="phone" class="footer__icon"/>
            <div>
              <div>{{ address.phone1 }}</div>
              <div v-if="address.phone2">{{ address.phone2 }}</div>
            </div>
          </div>
          <div class="footer__icon-line">
            <Icon id="fax" class="footer__icon"/>
            <div>{{ address.fax }}</div>
          </div>
        </div>
        <div :class="{'mt-50': isMobile}">
          <p class="paragraph">{{ $beet.options.general.openHours }}</p>
          <lang-router-link v-if="$beet.options.general.contactPage" :to="{name: $beet.options.general.contactPage}" class="button mt-30">{{ $beet.options.general.contact.button }}</lang-router-link>
          <a v-else-if="$beet.options.general.contactLink" :href="$beet.options.general.contactLink" target="_blank" class="button mt-30">{{ $beet.options.general.contact.button }}</a>
          <a href="https://portail.dvsf.ca" target="_blank" class="button__portal mt-30">
            {{ $t('secure') }}
            <Icon id="arrow"/>
          </a>
        </div>
      </div>
      <div class="footer__investia">
        <img src="@/assets/investia.svg" alt="Investia" class="footer__investia-logo">
        <p class="paragraph mt-30">{{ $beet.options.general.investia.text }}</p>
        <p class="mt-10"><a class="footer__investia-link" target="_blank" :href="$beet.options.general.investia.policy.link">{{ $beet.options.general.investia.policy.text }}</a> - <lang-router-link class="footer__investia-link" :to="{ name: 'disclaimer' }">{{ $beet.options.general.investia.disclaimer }}</lang-router-link></p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Footer",

  inject: ['mq', 'emitter'],
  computed: {
    isMobile() {
      return this.mq.mdMinus;
    },
  }
}
</script>

<style scoped>

</style>
