<template>
	<Transition name="fade">
		<SiteNav v-if="$beet.isReady" @scrollBottom="scrollBottom" />
	</Transition>
	<router-view v-slot="{ Component }">
		<Transition name="fade">
			<component v-if="$beet.isReady" :is="Component" />
		</Transition>
	</router-view>
	<Transition name="fade">
		<div v-if="$beet.isReady" ref="footer">
			<Footer />
			<Gdpr :options="{ backgroundColor: '#ffffff', color: '#032d59' }" />
			<FamilyTransition />
		</div>
	</Transition>
	<Transition name="fade">
		<IntersiteNav v-if="$beet.isReady" />
	</Transition>
</template>

<script>
import Gdpr from "@/components/gdpr/Gdpr.vue";
import FamilyTransition from "@/components/FamilyTransition.vue";
import Footer from "@/components/Footer";
import SiteNav from "@/components/SiteNav";
import { useLangRouter } from "@/extensions/langRouter";
import IntersiteNav from "./components/IntersiteNav.vue";

export default {
	name: "App",
	setup() {
		useLangRouter();
	},
	components: { FamilyTransition, Footer, Gdpr, SiteNav, IntersiteNav },
	methods: {
		scrollBottom() {
			this.$refs["footer"].scrollIntoView({ behavior: "smooth" });
		},
	},
	watch: {
		"$i18n.locale": {
			handler: function (to) {
				this.$beet.fetchCoreData(to);
			},
			immediate: true,
		},
	},
};
</script>
