<template>
  <div class="card" :class="styleType[type]">
    <img class="card__img" :src="img" alt="">
    <div class="card__content"><slot/></div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    img: String,
    type: String
  },
  computed: {
    styleType () {
      return {
        dark: "card__dark",
        light: "card__light"
      }
    }
  },
}
</script>

<style scoped>

</style>